import React, { useState, useEffect } from 'react';
import { MuiThemeProvider } from "@material-ui/core/styles";

import Home from './home/Home';
import Product from './product/Product';
import Nav from './Nav';
import SlidingDrawer from './SlidingDrawer';
import ShoppingCart from './cart/ShoppingCart';
import RegisterDialog from './registration/RegisterDialog';
import ProductDialog from './product_listing/ProductDialog';
import theme from "../theme";
import api from '../api';

import '../css/App.css';

const TAG_HOME = "home";
const TAG_PRODUCT = "prduct";

const KEY_CART_ID = "shopping-cart-id";

const App = () => {
    const [scrollTops, setScrollTops] = useState(null);
    const [menuOpen, setMenuOpen] = useState(false);
    const [showProduct, setShowProduct] = useState(_shouldShowProductPage());
    const [showCart, setShowCart] = useState(false);
    const [cart, setCart] = useState(null);
    const [currentPage, setCurrentPage] = useState(_shouldShowProductPage() ? TAG_PRODUCT : TAG_HOME);
    const [products, setProducts] = useState(null);
    const [showRegisterDialog, setShowRegisterDialog] = useState(_shouldShowRegisterDialog());
    const [registerSource, setRegisterSource] = useState(null);
    const [showProductDialog, setShowProductDialog] = useState(false);
    const [cartLoading, setCartLoading] = useState(false);

    console.log("env", process.env);

    useEffect(() => {
        window.addEventListener('popstate', (event) => {
            setShowProduct(_shouldShowProductPage());
        });

        if (localStorage.getItem(KEY_CART_ID)) {
            api.getCart(localStorage.getItem(KEY_CART_ID))
                .then(cart => setCart(cart))
                .catch(err => console.error(err));
        }

        api.getProducts()
            .then(response => {
                console.log("GOT MAH PRODUCTS!!!!", response.data);
                setProducts(response.data);
            })
            .catch(err => console.error(err));
    }, []);

    const onScroll = (tag, event) => {
        let scrollTop = event.target.scrollTop;
        setScrollTops({
            ...scrollTops,
            [tag]: scrollTop
        });
    };

    const onBuyClick = (event) => {
        setShowProductDialog(true);
    };

    const onAddProductToCartClick = (product, quantity) => {
        // setCartLoading(true);
        if (!cart) {

            // Optimistically create a cart
            setCart({
                id: -1,
                line_items: {
                    physical_items: [
                        {
                            id: -1,
                            product_id: product.id,
                            quantity
                        }
                    ]
                }
            });

            api.createCart(product.id, quantity)
                .then(cart => {
                    console.log("createCart", cart);
                    // setCartLoading(false);
                    setCart(cart);
                    localStorage.setItem(KEY_CART_ID, cart.id);
                })
                .catch(err => {
                    console.error(err);
                    // setCartLoading(false);
                })
            // shopClient.createCart().then(cart => {
            //     localStorage.setItem(KEY_CART_ID, cart.id)
            //     addProductToCart(cart, product, quantity);
            // });
        } else {
            // Optimistically add a line item
            setCart({
                ...cart,
                line_items: {
                    ...cart.line_items,
                    physical_items: [
                        ...cart.line_items.physical_items,
                        {
                            id: -1,
                            product_id: product.id,
                            quantity
                        }
                    ]
                }
            });

            api.addItem(cart.id, product.id, quantity)
                .then(cart => {
                    console.log("addItem", cart);
                    setCartLoading(false);
                    setCart(cart);
                })
                .catch(err => {
                    console.error(err);
                    setCartLoading(false);
                });
            // addProductToCart(cart, product, quantity);
        }
    };

    // const addProductToCart = (cart, product, quantity) => {
    //     if (cart) {
    //         cart.createLineItemsFromVariants({variant: product.variants[0], quantity: quantity}).then(newCart => {
    //             setCart(newCart);
    //         });
    //     } else {
    //         AnalyticsTracker.trackEvent("error", "add to cart", "no cart passed", 0);
    //     }
    // };

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    const showProductWrapper = () => {
        window.history.pushState({}, 'product', '/product');
        setShowProduct(true);
        setCurrentPage(TAG_PRODUCT);
    };

    const hideProduct = () => {
        setShowProduct(false);
        setCurrentPage(TAG_HOME);
    };

    const showCartWrapper = () => {
        setShowCart(true);
    };

    const hideCart = () => {
        setShowCart(false);
    };

    const onQuantityUpClick = (index) => {
        if (cart) {
            const firstLineItemId = cart.line_items.physical_items[index].id;
            const currentQuantity = cart.line_items.physical_items[index].quantity;
            const productId = cart.line_items.physical_items[index].product_id;

            // Optimistically update our local data
            cart.line_items.physical_items[index].quantity++;
            cart.line_items.physical_items[index].extended_sale_price = cart.line_items.physical_items[index].sale_price * cart.line_items.physical_items[index].quantity;
            setCart({
                ...cart
            });

            api.updateItem(cart.id, firstLineItemId, productId, currentQuantity + 1)
                .then(cart => {
                    setCart(cart);
                })
                .catch(err => {
                    console.error(err);
                });
        }
    };

    const onQuantityDownClick = (index) => {
        if (cart) {
            const firstLineItemId = cart.line_items.physical_items[index].id;
            const currentQuantity = cart.line_items.physical_items[index].quantity;
            const productId = cart.line_items.physical_items[index].product_id;

            if (currentQuantity === 1) {
                onRemoveClick(index);
            } else {
                //Optimistically update our local data
                cart.line_items.physical_items[index].quantity--;
                cart.line_items.physical_items[index].extended_sale_price = cart.line_items.physical_items[index].sale_price * cart.line_items.physical_items[index].quantity;
                setCart({
                    ...cart
                });

                api.updateItem(cart.id, firstLineItemId, productId, currentQuantity - 1)
                    .then(cart => {
                        setCart(cart);
                    })
                    .catch(err => {
                        console.error(err);
                    });
            }
        }
    };

    const onRemoveClick = (index) => {
        if (cart) {
            const lineItemId = cart.line_items.physical_items[index].id;

            // Optimistically update our cart, for better UX
            const items = [...cart.line_items.physical_items];
            items.splice(index, 1);

            setCart({
                ...cart,
                line_items: {
                    ...cart.line_items,
                    physical_items: items
                }
            });

            api.deleteItem(cart.id, lineItemId)
                .then(cart => {
                    setCart(cart);
                    setCartLoading(false);
                })
                .catch(err => {
                    console.error(err);
                    setCartLoading(false);
                });
        }
    };

    const onContinueShoppingClick = () => {
        hideCart();
        window.setTimeout(() => {
            onBuyClick();
        }, 300);
    };

    const getScrollTop = () => {
        return scrollTops && scrollTops[currentPage] ? scrollTops[currentPage] : 0;
    };

    const hideDialog = () => {
        setShowRegisterDialog(false);
    };

    const hideProductDialog = () => {
        setShowProductDialog(false);
    };

    const showDialog = (source) => {
        setShowRegisterDialog(true);
        setRegisterSource(source);
    };

    return (
        <MuiThemeProvider theme={theme}>
            <div className="App">
                <Home
                    show={!showProduct}
                    onShowProductClick={showProductWrapper}
                    onScroll={event => onScroll(TAG_HOME, event)}
                    onMessageClick={showDialog} />
                <Product
                    show={showProduct}
                    onScroll={event => onScroll(TAG_PRODUCT, event)}
                    onOrderClick={onBuyClick}
                    onMessageClick={showDialog} />
                <Nav
                    onLogoClick={hideProduct}
                    scrollTop={getScrollTop()}
                    onMenuClick={toggleMenu}
                    onOrderClick={onBuyClick}
                    onRegisterClick={showDialog}
                    onProductClick={showProductWrapper}/>
                <SlidingDrawer
                    menuOpen={menuOpen}
                    onMenuClose={toggleMenu}
                    onNavItemClick={hideProduct}
                    onOrderClick={onBuyClick}
                    onRegisterClick={showDialog}
                    onProductClick={showProductWrapper}/>
                <ShoppingCart
                    show={showCart}
                    onCloseClick={hideCart}
                    onShowCartClick={showCartWrapper}
                    cart={cart}
                    onQuantityUpClick={onQuantityUpClick}
                    onQuantityDownClick={onQuantityDownClick}
                    onRemoveClick={onRemoveClick}
                    products={products}
                    onContinueShoppingClick={onContinueShoppingClick} />
                <RegisterDialog
                    open={showRegisterDialog}
                    onCloseDialog={hideDialog}
                    source={registerSource} />
                <ProductDialog
                    open={showProductDialog}
                    onCloseDialog={hideProductDialog}
                    products={products}
                    onAddProductToCartClick={onAddProductToCartClick}
                    cart={cart}
                    showCart={showCartWrapper}
                    cartLoading={cartLoading}
                    setCartLoading={setCartLoading} />
            </div>
        </MuiThemeProvider>
    )
};

function _shouldShowProductPage() {
    let parts = document.location.pathname.split(/\//);
    // alert(parts.length);
    return (parts && parts.length > 1 && parts[1] === "product");
}

function _shouldShowRegisterDialog() {
    let parts = document.location.pathname.split(/\//);
    // alert(parts.length);
    return (parts && parts.length > 1 && parts[1] === "register");
}

export default App;
