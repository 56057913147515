import React from 'react';
import Grid from '@material-ui/core/Grid';

import tabletLadder from '../../img/tablet/ladder-converted.png';
import mobileLadder from '../../img/phone/ladder-converted.png';

import '../../css/HomeIntro.css';

const HomeIntro = () => {
    return (
        <div id="home-intro" className="home-intro outter-padding">
            <Grid container spacing={6}>
                <Grid item xs={12}>
                    <h1 className="home-intro-headline">
                        Lighted end caps help reduce firefighter injury
                    </h1>
                </Grid>
            </Grid>
            <Grid container spacing={6} className="home-intro-features">
                <Grid item xs={12} sm={4} className="flex column align-center">
                    <h2 className="home-intro-feature-headline">
                        Step off safely
                    </h2>
                    <div className="home-intro-feature-content pt-sans">
                        Higher visibility in the departure area means fewer preventable injuries.
                    </div>
                    <div className="home-intro-feature-divider" />
                </Grid>
                <Grid item xs={12} sm={4}>
                </Grid>
                <Grid item xs={12} sm={4} className="flex column align-center">
                    <h2 className="home-intro-feature-headline">
                        Get out quickly
                    </h2>
                    <div className="home-intro-feature-content pt-sans">
                        No matter who set the ladder, beacon lights help you find it through smoke and darkness.
                    </div>
                    <div className="home-intro-feature-divider" />
                </Grid>
                <Grid item xs={12} className="home-intro-feature-ladder-mobile center">
                    <img src={mobileLadder} className="home-intro-feature-ladder-mobile-img" alt="ladder | Ladder Light" />
                </Grid>
                <Grid item xs={12} className="home-intro-feature-ladder-tablet center">
                    <img src={tabletLadder} className="home-intro-feature-ladder-tablet-img" alt="ladder | Ladder Light" />
                </Grid>
            </Grid>
        </div>
    );
};

export default HomeIntro;
