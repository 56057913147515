var Validator = {
    validate: function(key, errorMessage, testFunc, errors) {
        var result = testFunc();
        errors[key] = result ? "" : errorMessage;
        return result;
    },
    nonEmptyValidator: function(value) {
        return value.length > 0;
    },
    emailValidator: function(email) {
        var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    },
    twitterValidator: function(twitterHandle) {
        var re = /^@?[a-zA-Z0-9]+$/
        return re.test(twitterHandle);
    },
    matchingValidator: function(value1, value2) {
        return value1 === value2;
    },
    passwordValidator: function(value) {
        return value.length > 7 && value.match(/[A-Z]/) && value.match(/[a-z]/) && value.match(/[0-9]/);
    },
    phoneValidator: function(value) {
        return value.match(/[0-9]{3}-[0-9]{3}-[0-9]{4}/);
    }
}

export default Validator;
