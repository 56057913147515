import { createMuiTheme } from "@material-ui/core/styles";

const theme = createMuiTheme({
    "breakpoints": {
        values: {
            "xs": 360,
            "sm": 768,
            "md": 960,
            "lg": 1200,
            "xl": 1920
        }
    },
    "palette": {
        type: "dark",
        primary: {
            "50": "#ffffff",
            "100": "#ffffff",
            "200": "#ffffff",
            "300": "#ffffff",
            "400": "#ffffff",
            "500": "#ffffff",
            "600": "#ffffff",
            "700": "#ffffff",
            "800": "#ffffff",
            "900": "#ffffff",
            "A100": "#ffffff",
            "A200": "#ffffff",
            "A400": "#ffffff",
            "A700": "#ffffff",
            contrastDefaultColor: "#ffffff"
        },
        error: {
            "50": "#000000",
            "100": "#000000",
            "200": "#000000",
            "300": "#000000",
            "400": "#000000",
            "500": "#000000",
            "600": "#000000",
            "700": "#000000",
            "800": "#000000",
            "900": "#000000",
            "A100": "#000000",
            "A200": "#000000",
            "A400": "#000000",
            "A700": "#000000",
            contrastDefaultColor: "#0000000"
        }
    },
    "overrides": {
        Select: {
            root: {
                color: "#000000"
            }
        }
    }
});

export default theme;