import firebase from "firebase/app";
import "firebase/database";
import "firebase/functions";

firebase.initializeApp({
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
});

// For testing
// firebase.functions().useEmulator("localhost", 5001);

let lastCart = null;
let numRequestsInFlight = 0;

// Start a crude API. Make this proper if this grows
const api = {
    getProducts: () => {
        return firebase.functions().httpsCallable("getProducts")({});
    },
    getCart: (cartId) => {
        return firebase.functions().httpsCallable("getCart")({ cartId })
            .then(response => {
                lastCart = response.data.data;
                return lastCart;
            });
    },
    createCart: (productId, quantity) => {
        return firebase.functions().httpsCallable("createCart")({ productId, quantity })
            .then(response => {
                lastCart = response.data.data;
                return lastCart;
            });
    },
    addItem: (cartId, productId, quantity) => {
        return firebase.functions().httpsCallable("addItem")({ cartId, productId, quantity })
            .then(response => {
                lastCart = response.data.data;
                return lastCart;
            });
    },
    updateItem: (cartId, itemId, productId, quantity) => {
        numRequestsInFlight++;
        return firebase.functions().httpsCallable("updateItem")({ cartId, itemId, productId, quantity })
            .then(response => {
                numRequestsInFlight--;
                if (numRequestsInFlight === 0) {
                    lastCart = response.data.data;
                }
                return lastCart;
            })
            .catch(err => {
                numRequestsInFlight--;
                throw err;
            });
    },
    deleteItem: (cartId, itemId) => {
        numRequestsInFlight++;
        return firebase.functions().httpsCallable("deleteItem")({ cartId, itemId })
            .then(response => {
                numRequestsInFlight--;
                if (numRequestsInFlight === 0) {
                    console.log("no moar requests");
                    lastCart = response.data.data;
                }
                console.log("lastCart", lastCart);
                return lastCart;
            })
            .catch(err => {
                numRequestsInFlight--;
                throw err;
            });
    }
};

export default api;