import React from 'react';

import '../../css/Copyright.css';

const Copyright = () => {
    return (
        <div className="copyright outter-padding white pt-sans">
            &copy; 2017 Copyright. All rights reserved by Ladder Light LLC.
        </div>
    )
};

export default Copyright;
