import React from 'react';

import '../../css/Divider.css';

const Divider = ({ position }) => {
    return (
        <div className={"ll-divider ll-divider-" + position} />
    );
};

export default Divider;
