var ReactGA = require('react-ga');

ReactGA.initialize(getGAKey(), { debug: false });

var AnalyticsTracker = {
    trackPageView: function(pageName) {
        if (!document.location.href.match(/localhost|192\.|dev\./i)) {
            ReactGA.pageview(pageName);
        }
    },
    trackEvent: function(category, action, label, value) {
        if (!document.location.href.match(/localhost|192\.|dev\./i)) {
            ReactGA.event({
                category: category,
                action: action,
                label: label,
                value: value
            });
        }
    },
    setCustomDimension: function(dimension, value) {
        ReactGA.set({[dimension]: value});
    }
};

function getGAKey() {
    return 'UA-105450376-1';
}

export default AnalyticsTracker;
