import React from 'react';
import Grid from '@material-ui/core/Grid';

import ProductVideo from './ProductVideo';
import ProductOrderBumper from './ProductOrderBumper';
import ProductBuiltFromBest from './ProductBuiltFromBest';
import ProductDesignedBy from './ProductDesignedBy';
import ProductWeTested from './ProductWeTested';
import ProductCommitment from './ProductCommitment';
import Contact from '../common/Contact';
import AnalyticsTracker from '../common/AnalyticsTracker';
import Copyright from '../common/Copyright';

import expandArrow from '../../img/expand-arrow-1600@2x.png'

import '../../css/Product.css';
import '../../css/Common.css';

const CATEGORY = "product";

const Product = ({ onOrderClick, show, onScroll, onMessageClick }) => {

    const orderClickWrapper = () => {
        AnalyticsTracker.trackEvent(CATEGORY, "order click", "", 0);

        onOrderClick();
    };

    return (
        <div className={"product-container" + (show ? " product-container-show" : "")} onScroll={onScroll}>
            <div className="product-header outter-padding">
                <Grid container>
                    <Grid item xs={12} lg={6}>
                    </Grid>
                    <Grid item xs={12} lg={6} className="flex justify-center product-header-headline-container">
                        <h1 className="product-header-headline">
                            Designed to decrease <span className="orange">the risk of injury.</span>
                        </h1>
                    </Grid>
                </Grid>
                <div className="flex center">
                    <img src={expandArrow} className="expand-arrow product-expand-arrow" alt="scroll down | Ladder Light" />
                </div>
            </div>
            <ProductVideo />
            <ProductOrderBumper onOrderClick={orderClickWrapper} />
            <ProductBuiltFromBest />
            <ProductDesignedBy />
            <ProductWeTested />
            <div className="home-bottom-bumper">
                <Grid container spacing={24}>
                    <Grid item className="product-commitment" xs={12} sm={6}>
                        <ProductCommitment />
                    </Grid>
                    <Grid item className="contact" xs={12} sm={6}>
                        <Contact page="contact" onMessageClick={onMessageClick} />
                    </Grid>
                </Grid>
            </div>
            <Copyright />
        </div>
    );
}

export default Product;
