import React from 'react';

import AnalyticsTracker from './common/AnalyticsTracker';

import close from '../img/tablet/close-icon@3x.png'

import '../css/Common.css';
import '../css/SlidingDrawer.css';

const CATEGORY = "menu";

const SlidingDrawer = ({ onMenuClose, onNavItemClick, onOrderClick, onRegisterClick, onProductClick, menuOpen }) => {

    const onNavItemClickWrapper = (item) => {
        AnalyticsTracker.trackEvent(CATEGORY, "nav item click", item, 0);

        onMenuClose();
        onNavItemClick();

        window.history.pushState({}, "home", "/#" + item);
    };

    const onOrderClickWrapper = () => {
        AnalyticsTracker.trackEvent(CATEGORY, "order click", "", 0);

        onMenuClose();
        window.setTimeout(() => {
            onOrderClick();
        }, 200);
    };

    const onCloseClick = () => {
        AnalyticsTracker.trackEvent(CATEGORY, "close click", "", 0);

        onMenuClose();
    };

    const onDownloadBrochureClick = () => {
        AnalyticsTracker.trackEvent(CATEGORY, "download brochure click", "", 0);

        onMenuClose();
    };

    const onDownloadFitlistClick = () => {
        AnalyticsTracker.trackEvent(CATEGORY, "download fit list click", "", 0);
        onMenuClose();
    };

    const onRegisterClickWrapper = () => {
        AnalyticsTracker.trackEvent(CATEGORY, "register click", "", 0);

        onRegisterClick('register');
        onMenuClose();
    };

    const onVideoClick = () => {
        AnalyticsTracker.trackEvent(CATEGORY, "video click", "", 0);

        onMenuClose();
    };

    const onProductClickWrapper = () => {
        AnalyticsTracker.trackEvent(CATEGORY, "product click", "", 0);

        onProductClick();
        onMenuClose();
    };

    const onPhoneClick = () => {
        AnalyticsTracker.trackEvent(CATEGORY, "phone click", "", 0);

        onMenuClose();
    };

    return (
        <div className={"sliding-drawer orange-bg" + (menuOpen ? " sliding-drawer-out" : " balls")}>
            <div className="sliding-drawer-padding">
                <div className="sliding-drawer-header flex pt-sans align-center">
                    <div className="flex-1 align-left">
                        menu
                    </div>
                    <div className="sliding-drawer-close-button-container flex column justify-center align-end" onClick={onCloseClick}>
                        <img src={close} className="sliding-drawer-close-button" alt="sliding drawer close button | Ladder Light" />
                    </div>
                </div>
                <div className="sliding-drawer-nav-links">
                    <div className="sliding-drawer-nav-link" onClick={onProductClickWrapper}>
                        Product
                    </div>
                    <div className="sliding-drawer-nav-link" onClick={() => onNavItemClickWrapper("home-about")}>
                        <a href="/#home-about">
                            About
                        </a>
                    </div>
                    <div className="sliding-drawer-nav-link" onClick={() => onNavItemClickWrapper("home-contact")}>
                        <a href="/#home-contact">
                            Contact Us
                        </a>
                    </div>
                    <div className="sliding-drawer-nav-link" onClick={onDownloadBrochureClick}>
                        <a href="/PR_11.14.17.pdf" target="_blank">
                            Press Release 11.14.2017
                        </a>
                    </div>
                    <div className="sliding-drawer-nav-link" onClick={onDownloadBrochureClick}>
                        <a href="/brochure.pdf" target="_blank">
                            Download brochure
                        </a>
                    </div>
                    <div className="sliding-drawer-nav-link" onClick={onDownloadFitlistClick}>
                        <a href="/fit-list.pdf" target="_blank">
                            Download fit list
                        </a>
                    </div>
                    <div className="sliding-drawer-nav-link" onClick={onVideoClick}>
                        <a href="https://firebasestorage.googleapis.com/v0/b/ladder-light.appspot.com/o/LadderLight.mp4?alt=media&token=08e747b6-017c-407b-b046-fdae441f9116" rel="noreferrer" target="_blank">
                            Video
                        </a>
                    </div>
                    <div className="sliding-drawer-nav-link" onClick={onRegisterClickWrapper}>
                        Have Questions?
                    </div>
                    <div className="sliding-drawer-nav-link" onClick={onPhoneClick}>
                        <a href="tel:1-512-566-7301">
                            512.566.7301
                        </a>
                    </div>
                    <div className="ll-button ll-button-dark" style={{margin: "20px 0 20px"}} onClick={onOrderClickWrapper}>
                        order now
                    </div>
                </div>
            </div>
        </div>
    )
};

export default SlidingDrawer;
