import React from 'react';

import '../../css/Common.css';
import '../../css/ProductCommitment.css';

import madeInMurica from '../../img/made-in-usa.png';

const ProductCommitment = () => {
    return (
        <div className="product-commitment-container flex">
            <div className="product-commitment-content outter-padding">
                <h2 className="product-commitment-headline orange">
                    The only thing stronger than our product is our commitment.
                </h2>
                <div className="pt-sans flex">
                    <div>
                        These end caps are built tough and backed by a lifetime warranty for defects in workmanship or materials. But we care more about your loyalty than any legal warranty. So, if you find something you don’t like, make us your first call.
                    </div>
                    <div>
                        <img className="product-commitment-made-in-murica" src={madeInMurica} alt="Made in the USA" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProductCommitment;
