import React from 'react';

import orangeLogoWordmark from '../../img/logo-red.svg';
import nfpa from '../../img/nfpa.svg';
import fama from '../../img/fama.svg';

import '../../css/HomeAbout.css';
import '../../css/Common.css';

const HomeAbout = () =>  {

    return (
        <div id="home-about" className="home-about-wrapper outter-padding">
            <div>
                <img src={orangeLogoWordmark} className="home-about-ll-logo" alt="LadderLight logo and wordmark | Ladder Light" />
            </div>
            <div className="home-about-content pt-sans">
                We brought firefighting experience together with quality craftsmanship, American manufacturing, and good business. We understand the rigorous work it takes to turn a good idea into a product we’re honored to bring into your station. We’re thorough in our testing and unwavering in our dedication to the brave men and women who risk their lives for ours.
            </div>
            <div className="home-about-logos">
                <div>
                    <img src={nfpa} className="home-about-logo home-about-logo-nfpa" alt="NFPA | Ladder Light" />
                    <div className="home-about-logo home-about-logo-nfpa-member">
                        MEMBER
                    </div>
                </div>
                <div>
                    <img src={fama} className="home-about-logo home-about-logo-fama" alt="FAMA | Ladder Light" />
                </div>
            </div>
        </div>
    );
};

export default HomeAbout;
