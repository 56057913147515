import React from 'react';
import numeral from 'numeral';

import AnalyticsTracker from '../common/AnalyticsTracker';
import Divider from '../common/Divider';

import close from '../../img/ic_close_black_24px.svg';
import cartImg from '../../img/ic_shopping_cart_white_24px.svg';

import '../../css/Common.css';
import '../../css/ShoppingCart.css';

const CATEGORY = "cart";

const ShoppingCart = ({ cart, onShowCartClick, onCloseClick, products, onQuantityUpClick, onQuantityDownClick, show, onRemoveClick, onContinueShoppingClick }) => {

    const onCheckoutClick = () => {
        AnalyticsTracker.trackEvent(CATEGORY, "checkout click", "", 0);

        if (cart) {
            window.open(cart.redirect_urls?.checkout_url, "checkout");
        }
    };

    const onCartIconClick = () => {
        AnalyticsTracker.trackEvent(CATEGORY, "cart icon click", "", 0);
        onShowCartClick();
    };

    const onCloseClickWrapper = () => {
        AnalyticsTracker.trackEvent(CATEGORY, "close click", "", 0);
        onCloseClick();
    };

    const cartHasLineItems = () => {
        return cart && cart.line_items?.physical_items && cart.line_items.physical_items.length;
    };

    console.log("cart", cart);

    var items = cart ? cart.line_items.physical_items : [];
    var emptyMessage = items.length > 0 ? "" : "Your cart is empty.";

    return (
        <div>
            <div className={"cart-indicator orange-bg flex center" + (cartHasLineItems() ? " cart-indicator-show" : "")} onClick={onCartIconClick}>
                <img src={cartImg} alt="shopping cart icon | Ladder Light" />
            </div>
            <div className={"cart-container orange-bg flex column" + (show ? " cart-container-show" : "")}>
                <div className=" flex-1 flex column">
                    <div className="cart-padding cart-header flex pt-sans align-center">
                        <div className="flex-1 align-left forza" style={{color: "#000000"}}>
                            shopping cart
                        </div>
                        <div className="cart-close-button-container flex column justify-center align-end" onClick={onCloseClickWrapper}>
                            <img src={close} className="cart-close-button" alt="shopping cart close button | Ladder Light" />
                        </div>
                    </div>
                    <Divider position="top" />
                    <div className="flex-1 cart-padding" style={{overflowY: "scroll", scrollBars: "hidden"}}>
                        {items.map((item, index) => {
                            return (
                                <div key={"cart-item-" + index}>
                                    <CartItem
                                        item={item}
                                        index={index}
                                        products={products}
                                        onQuantityUpClick={onQuantityUpClick}
                                        onQuantityDownClick={onQuantityDownClick}
                                        onRemoveClick={onRemoveClick} />
                                </div>
                            )
                        })}
                        <div className="cart-checkout-btn ll-button ll-button-dark" onClick={onContinueShoppingClick}>
                            Continue Shopping
                        </div>
                    </div>
                    {emptyMessage &&
                        <div className="flex-1">
                            { emptyMessage }
                        </div>
                    }
                </div>
                <div className="pt-sans white">
                    <Divider position="bottom" />
                    <div className="cart-padding">
                        <div className="flex">
                            <div className="cart-subtotal-label flex-1">
                                Subtotal
                            </div>
                            <div>
                                {cart ? numeral(cart.cart_amount).format("$0,0.00") : "$0.00"}
                            </div>
                        </div>
                        <div className="cart-disclaimer">
                            Shipping and discount codes are added at checkout.
                        </div>
                        <div className="cart-checkout-btn ll-button ll-button-dark" onClick={onCheckoutClick}>
                            Checkout
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

const CartItem = ({ onQuantityUpClick, onQuantityDownClick, index, products, item, onRemoveClick }) => {

    const onQuantityUpClickWrapper = () => {
        AnalyticsTracker.trackEvent(CATEGORY, "up click", "", 0);
        onQuantityUpClick(index);
    };

    const onQuantityDownClickWrapper = () => {
        AnalyticsTracker.trackEvent(CATEGORY, "down click", "", 0);

        onQuantityDownClick(index);
    };

    const getProductById = (productId) => {
        if (products && products.length) {
            const product = products.find(product => product.id === productId);
            if (product) {
                return product;
            }
        }
        return {title: ""};
    };

    let product = getProductById(item.product_id);

    return (
        <div className="cart-item-container pt-sans">
            <div className="flex cart-item-top">
                <div className="cart-item-title flex-1">
                    {product.name}
                </div>
                <div>
                    ({item.quantity})
                </div>
            </div>
            <div className="cart-item-description" dangerouslySetInnerHTML={{__html: product.description}} />
            <div className="flex align-center">
                <div className="flex flex-1 align-center">
                    <div className="ll-button ll-button-dark" onClick={onQuantityDownClickWrapper}>&#8209;</div>
                    <div className="cart-item-quantity white">
                        {item.quantity}
                    </div>
                    <div className="ll-button ll-button-dark" onClick={onQuantityUpClickWrapper}>+</div>
                </div>
                <div className="white">
                    ${item.extended_sale_price}
                </div>
            </div>
            <div className="flex">
                <div className="cart-item-remove" onClick={() => onRemoveClick(index)}>
                    Remove from Cart
                </div>
            </div>
        </div>
    );
};

export default ShoppingCart;
