import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core';
import Slide from '@material-ui/core/Slide';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
import Input from '@material-ui/core/Input';
import CircularProgress from "@material-ui/core/CircularProgress";
import numeral from 'numeral';

import AnalyticsTracker from '../common/AnalyticsTracker';
import Divider from '../common/Divider';

import cartImg from '../../img/ic_shopping_cart_black_24px.svg';

import '../../css/ProductDialog.css';
import '../../css/Common.css';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

const CATEGORY = "productlisting";

const MAX_NUM_PRODUCTS = 10;

const ProductDialog = ({ onCloseDialog, showCart, cart, open, products, onAddProductToCartClick, cartLoading }) => {
    const [highlightCart, setHighlightCart] = useState(false);

    const closeDialog = () => {
        onCloseDialog();
        if (cart && cart.line_items.physical_items.length) {
            window.setTimeout(() => {
                showCart();
            }, 300);
        }
    };

    const handleCloseDialog = () => {
        AnalyticsTracker.trackEvent(CATEGORY, "handel close dialog", "", 0);

        closeDialog();
    };

    const onHighlightCart = () => {
        setHighlightCart(true);
        window.setTimeout(() => {
            setHighlightCart(false);
        }, 500);
    };

    const getLayout = () => {
        if (!products) {
            return (
                <div>
                    Loading...
                </div>
            )
        } else if (!products.length) {
            return (
                <div>
                    No products to display....
                </div>
            )
        }
        return (
            <div>
                {products.map((product, index) => {
                    return (
                        <ProductItem
                            key={"product-" + index}
                            product={product}
                            index={index}
                            onAddProductToCartClick={onAddProductToCartClick}
                            highlightCart={onHighlightCart}
                            cartLoading={cartLoading} />
                    );
                })}
            </div>
        );
    };

    const getTotalNumCartItems = () => {
        let numItems = 0;
        cart.line_items.physical_items.forEach((lineItem, index) => {
           numItems += lineItem.quantity;
        });
        return numItems;
    };

    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            onClose={handleCloseDialog}>
            <DialogTitle style={{backgroundColor: "#eb4727", padding: "0px", width: "100%"}}>
                <div className="productlisting-title forza flex">
                    <div className="flex-1">
                        Products
                    </div>
                    {cart && cart.line_items.physical_items.length ?
                        <div
                            className={"pt-sans flex center productlisting-cart-quantity" + (highlightCart ? " productlisting-cart-quantity-large" : "")}
                            onClick={closeDialog}>
                            ({getTotalNumCartItems()})
                            <img src={cartImg} alt="shopping cart icon | Ladder Light" className="productlisting-cart-icon" />
                        </div>
                        :
                        null
                    }
                </div>
                <Divider position="top" />
            </DialogTitle>
            <DialogContent style={{backgroundColor: "#eb4727"}}>
                {getLayout()}
            </DialogContent>
            <DialogActions classes={{
                action: "productlisting-action"
            }}
                           style={{backgroundColor: "#eb4727", padding: "0px", margin: "0px", flexDirection: "column", width: "100%"}}>
                <Divider position="bottom" />
                <div className="productlisting-actions flex">
                    <div className="productlisting-action-ok white pt-sans flex center" onClick={closeDialog}>
                        {cart && cart.line_items.physical_items.length ? "review cart" : "done"}
                    </div>
                </div>
            </DialogActions>
        </Dialog>
    )
};

const ProductItem = ({ highlightCart, onAddProductToCartClick, product, index, cartLoading }) => {
    const [quantity, setQuantity] = useState(1);

    const getQuantityMenuItems = () => {
        let menuItems = [];
        for (let index = 1; index <= MAX_NUM_PRODUCTS; index++) {
            menuItems.push(
                <MenuItem value={index} key={"menuitem-" + index}>{index}</MenuItem>
            );
        }
        return menuItems;
    };

    const onAddProductToCartClickWrapper = (product, quantity) => {
        AnalyticsTracker.trackEvent(CATEGORY, "add product to cart", product.name, quantity);

        highlightCart();
        onAddProductToCartClick(product, quantity);
    };

    const onQuantityChange = (product, index, event) => {
        AnalyticsTracker.trackEvent(CATEGORY, "quantity changed", product.name, event.target.value);

        console.log("onQuantityChange: %d", event.target.value);
        setQuantity(event.target.value);
    };

    console.log("cartLoading", cartLoading);

    return (
        <div>
            <div className="product-item-container flex">
                <Grid container spacing={3}>
                    <Grid item xs={3} sm={2}>
                        <div className="product-item-image-container">
                            {product.primary_image && product.primary_image.thumbnail_url ?
                                <div className="product-item-image" style={{backgroundImage: `url(${product.primary_image.thumbnail_url})`}} />
                                :
                                <div style={{height: "50px", width: "50px"}} />
                            }
                        </div>
                    </Grid>
                    <Grid item xs={9} sm={7}>
                        <div>
                            <div className="product-item-title pt-sans">
                                {product.name}
                            </div>
                            <div className="product-item-description pt-sans" dangerouslySetInnerHTML={{__html: product.description}} />
                            <div className="product-item-price pt-sans">
                                {numeral(product.price).format('$0,0.00')}
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <div className="product-item-add-button-container">
                            <Grid container>
                                <Grid item xs={3} sm={12}>
                                    <div className="product-item-quantity">
                                        <Select
                                            value={quantity}
                                            onChange={event => onQuantityChange(product, index, event)}
                                            input={<Input fullWidth />}>
                                            {getQuantityMenuItems()}
                                        </Select>
                                    </div>
                                </Grid>
                                <Grid item xs={9} sm={12}>
                                    {cartLoading ?
                                        <div className="flex justify-center">
                                            <CircularProgress size={30} />
                                        </div> :
                                        <div className="ll-button ll-button-dark product-item-add-button" onClick={() => onAddProductToCartClickWrapper(product, quantity)}>
                                            add to cart
                                        </div>
                                    }
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>
                </Grid>
            </div>
        </div>
    )
};

export default ProductDialog;
