import React from 'react';

import orangeLogo from '../../img/logo-symbol.svg';

import '../../css/Common.css';
import '../../css/HomeProduct.css';

const HomeProduct = ({ onShowProductClick }) => {
    return (
        <div id="home-product" className="home-product-summary outter-padding">
            <div className="home-product-summary-info-container">
                <div className="home-product-symmary-info-container-logo">
                    <img src={orangeLogo} alt="LadderLight logo | Ladder Light" className="home-product-summary-logo" />
                </div>
                <div className="home-product-symmary-info-container-headline">
                    <h2 className="home-product-symmary-info-container-headline-top">
                        Smarter end caps.
                    </h2>
                    <h2 className="home-product-symmary-info-container-headline-bottom">
                        Safer firefighters.
                    </h2>
                </div>
                <div className="home-product-summary-info-content pt-sans">
                    Ladder Light replaces existing ladder end caps with a solution that illuminates the departure zone and acts as a beacon for firefighters and victims. They’re designed to work seamlessly with existing ladders and procedures and have been tested to perform in any conditions.
                </div>
                <div className="home-product-learn-more-wrapper">
                    <div className="ll-button ll-button-orange" onClick={onShowProductClick}>
                        Learn More
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HomeProduct;
