import React from 'react';

import '../../css/Common.css';
import '../../css/ProductDesignedBy.css';

const ProductDesignedBy = () => {
    return (
        <div className="product-designed-by-container flex">
            <div className="product-designed-by-content outter-padding">
                <h2 className="product-designed-by-headline">
                    Designed by world&#8209;class <span className="orange">craftsmen.</span>
                </h2>
                <div className="pt-sans">
                    Our team has made their careers designing for high&#8209;stakes environments with award&#8209;winning products ranging from military gear to medical devices.
                </div>
            </div>
        </div>
    );
};

export default ProductDesignedBy;
