import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import AnalyticsTracker from './common/AnalyticsTracker';

import navLogo from '../img/logo.svg';
import menuBtn from '../img/tablet/menu-icon@2x.png';

import '../css/Common.css';
import '../css/Nav.css';

const SCROLL_MAX = 300;
const CATEGORY = "nav";

const Nav = ({ scrollTop, onOrderClick, onLogoClick, onMenuClick, onRegisterClick, onProductClick }) => {
    const [aboutOpen, setAboutOpen] = useState(false);
    const [newsOpen, setNewsOpen] = useState(false);
    const [brochureOpen, setBrochureOpen] = useState(false);
    const [aboutAnchorElement, setAboutAnchorElement] = useState(null);
    const [newsAnchorElement, setNewsAnchorElement] = useState(null);
    const [brochureAnchorElement, setBrochureAnchorElement] = useState(null);

    const getAlpha = () => {
        return (Math.min(scrollTop, SCROLL_MAX) / SCROLL_MAX);
    };

    const orderClickWrapper = () => {
        AnalyticsTracker.trackEvent(CATEGORY, "order click", "", 0);

        onOrderClick();
    };

    const logoClickWrapper = () => {
        AnalyticsTracker.trackEvent(CATEGORY, "logo click", "", 0);

        onLogoClick();
        window.history.pushState({}, "home", "/");
    };

    const menuClickWrapper = () => {
        AnalyticsTracker.trackEvent(CATEGORY, "menu click", "", 0);

        onMenuClick();
    };

    const onNavItemClick = (item) => {
        AnalyticsTracker.trackEvent(CATEGORY, "nav item click", item, 0);

        onLogoClick();
        window.history.pushState({}, "home", "/#" + item);
    };

    const onDownloadBrochureClick = () => {
        AnalyticsTracker.trackEvent(CATEGORY, "download brochure click", "", 0);
        window.open('/brochure.pdf');
    };

    const onFitGuideClick = () => {
        AnalyticsTracker.trackEvent(CATEGORY, "download fit list click", "", 0);
        window.open('/fit-list.pdf');
    };

    const registerClickWrapper = () => {
        AnalyticsTracker.trackEvent(CATEGORY, "register click", "", 0);

        onRegisterClick('register');
    };

    const onVideoClick = () => {
        AnalyticsTracker.trackEvent(CATEGORY, "video click", "", 0);
    };

    const onPhoneClick = () => {
        AnalyticsTracker.trackEvent(CATEGORY, "phone click", "", 0);
    };

    const onAboutHover = (event) => {
        setAboutAnchorElement(event.target);
        setAboutOpen(true);
    };

    const onNewsHover = (event) => {
        setNewsAnchorElement(event.target);
        setNewsOpen(true);
    };

    const onBrochureHover = (event) => {
        setBrochureAnchorElement(event.target);
        setBrochureOpen(true);
    };

    const handleCloseMenus = () => {
        setAboutOpen(false);
        setNewsOpen(false);
        setBrochureOpen(false);
    };

    const onNewsClick = (pdf) => {
        AnalyticsTracker.trackEvent(CATEGORY, "new click", pdf, 0);
        window.open('/' + pdf);
    };

    const onAboutMenuClick = (item) => {
        onNavItemClick(item);
        handleCloseMenus();
        document.location.href = "/#" + item;
    };

    const productClickWrapper = () => {
        AnalyticsTracker.trackEvent(CATEGORY, "product click", "", 0);
        onProductClick();
    };

    return (
        <Grid container className="outter-padding nav-container" style={{backgroundColor: "rgba(0, 0, 0, " + getAlpha()  + ")"}}>
            <Grid item xs={7} lg={3} className="nav-logo-container flex align-center">
                <div className="nav-menu-btn align-center" onClick={menuClickWrapper}>
                    <img src={menuBtn} className="nav-menu-btn-img" alt="menu button | Ladder Light" />
                </div>
                <div className="flex center">
                    <img src={navLogo} className="nav-logo" onClick={logoClickWrapper} alt="Ladder Light logo" />
                </div>
            </Grid>
            <Grid item xs={5} lg={9} className="nav-link-container">
                <div className="nav-link" onClick={productClickWrapper}>
                    Product
                </div>
                <div className="nav-link" onMouseOver={onAboutHover}>
                    About
                </div>
                <div className="nav-link" onMouseOver={onNewsHover}>
                    News
                </div>
                <div className="nav-link" onMouseOver={onBrochureHover}>
                    Brochure
                </div>
                <a className="nav-link" href="https://firebasestorage.googleapis.com/v0/b/ladder-light.appspot.com/o/LadderLight.mp4?alt=media&token=08e747b6-017c-407b-b046-fdae441f9116" target="_blank" rel="noreferrer" onClick={onVideoClick}>
                    Video
                </a>
                <div className="nav-link" onClick={registerClickWrapper}>
                    Have Questions?
                </div>
                <a className="nav-link" href="tel:1-512-567-4491" onClick={onPhoneClick}>
                    512.567.4491
                </a>
                <div className="ll-button ll-button-orange" onClick={orderClickWrapper}>
                    order now
                </div>
                <Menu
                    id="about-menu"
                    anchorEl={aboutAnchorElement}
                    open={aboutOpen}
                    onClose={handleCloseMenus}>
                    <MenuItem classes={{root: 'subnav'}} onClick={() => onAboutMenuClick('home-about')}>About Us</MenuItem>
                    <MenuItem classes={{root: 'subnav'}} onClick={() => onAboutMenuClick('home-contact')}>Contact Us</MenuItem>
                </Menu>
                <Menu
                    id="news-menu"
                    anchorEl={newsAnchorElement}
                    open={newsOpen}
                    onClose={handleCloseMenus}>
                    <MenuItem classes={{root: 'subnav'}} onClick={() => onNewsClick('PR_11.14.17.pdf')}>Press Release 11.14.2017</MenuItem>
                </Menu>
                <Menu
                    id="brochure-menu"
                    anchorEl={brochureAnchorElement}
                    open={brochureOpen}
                    onClose={handleCloseMenus}>
                    <MenuItem classes={{root: 'subnav'}} onClick={onDownloadBrochureClick}>Brochure</MenuItem>
                    <MenuItem classes={{root: 'subnav'}} onClick={onFitGuideClick}>Fit List</MenuItem>
                </Menu>
            </Grid>
        </Grid>
    );
};

export default Nav;
