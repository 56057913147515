import React, { useEffect } from 'react';
import Grid from '@material-ui/core/Grid';

import HomeIntro from './HomeIntro';
import HomeProduct from './HomeProduct';
import HomeAbout from './HomeAbout';
import Contact from '../common/Contact';
import AnalyticsTracker from '../common/AnalyticsTracker';
import Copyright from '../common/Copyright';

import neverLoseSight from '../../img/never-lose-sight@2x.png';
import expandArrow from '../../img/expand-arrow-1600@2x.png'

import '../../css/Home.css';

const CATEGORY = "home";

const Home = ({ show, onScroll, onMessageClick, onShowProductClick }) => {

    useEffect(() => {
        AnalyticsTracker.trackPageView('home');
    }, []);


    const onExpandClick = () => {
        AnalyticsTracker.trackEvent(CATEGORY, 'expand click', "", 0);

        document.location.href = "/#home-intro";
    };

    const onLearnMoreClick = () => {
        AnalyticsTracker.trackEvent(CATEGORY, 'learn more click', "", 0);

        onShowProductClick();
    };

    return (
        <div className={"home-container" + (!show ? " home-container-hidden" : "")} onScroll={onScroll}>
            <div className="home-header">
                <div>
                    <img src={neverLoseSight} className="home-header-headline" alt="Never Lose Sight - Headline | Ladder Light" />
                </div>
                <div className="flex center home-header-expand-arrow">
                    <img src={expandArrow} className="expand-arrow" onClick={onExpandClick} alt="scroll down | Ladder Light" />
                </div>
            </div>
            <HomeIntro />
            <HomeProduct onShowProductClick={onLearnMoreClick} />
            <div className="home-bottom-bumper">
                <Grid container spacing={24}>
                    <Grid item className="home-about" xs={12} sm={6}>
                        <HomeAbout />
                    </Grid>
                    <Grid item className="contact" xs={12} sm={6}>
                        <Contact page="home" onMessageClick={onMessageClick} />
                    </Grid>
                </Grid>
            </div>
            <Copyright />
        </div>
    );
}

export default Home;
