import React, { useState } from 'react';
import { Dialog, DialogContent, Slide, TextField } from '@material-ui/core';
import firebase from "firebase/app";
import "firebase/database";

import AnalyticsTracker from '../common/AnalyticsTracker';
import Validator from '../../utils/Validator';

import '../../css/RegisterDialog.css';
import '../../css/Common.css';

const CATEGORY = "register";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

const RegisterDialog = ({ source, onCloseDialog, open }) => {
    const [registered, setRegistered] = useState(false);
    const [email, setEmail] = useState("");
    const [emailError, setEmailError] = useState(null);
    const [name, setName] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [phoneError, setPhoneError] = useState(null);
    const [message, setMessage] = useState("");

    const onEmailChange = (event) => {
        setEmail(event.target.value);
    };

    const onNameChange = (event) => {
        setName(event.target.value);
    };

    const onPhoneChange = (event) => {
        setPhoneNumber(event.target.value)
    };

    const onMessageChange = (event) => {
        setMessage(event.target.value);
    };

    const onEmailKeyDown = (event) => {
        if (event.keyCode === 13 && email.length > 5) {
            onRegisterClick();
        }
    };

    const onRegisterClick = (event) => {
        if (email || phoneNumber) {
            let emailValid = Validator.emailValidator(email);
            let phoneValid = Validator.phoneValidator(phoneNumber);

            setEmailError(emailValid ? null : "Please enter a valid email");
            setPhoneError(phoneValid ? null : "Please enter a valid phone number (###-###-####)");

            if (emailValid && phoneValid) {
                let theSource = source ? source : "url";

                // Get a reference to the database service
                var database = firebase.database();
                var newEmailRef = database.ref('registrations').push();
                newEmailRef.set({
                    name,
                    phoneNumber,
                    email,
                    message,
                    date: new Date().toString(),
                    source: theSource
                });

                setRegistered(true);

                AnalyticsTracker.trackEvent(CATEGORY, "email submitted", theSource, 0);
            } else {
                AnalyticsTracker.trackEvent(CATEGORY, "invalid email", "", 0);
            }
        } else {
            setEmailError("Please enter a valid email or phone number");
            setPhoneError("Please enter a valid phone number or email");
        }
    };

    const onDoneClick = () => {
        AnalyticsTracker.trackEvent(CATEGORY, "done click", "", 0);
        closeDialog();
    };

    const onCancelClick = () => {
        AnalyticsTracker.trackEvent(CATEGORY, "cancel click", "", 0);
        closeDialog();
    };

    const closeDialog = () => {
        onCloseDialog();

        window.setTimeout(() => {
            setRegistered(false);
            setEmail("");
            setEmailError(null);
            setName("");
            setPhoneNumber("");
            setPhoneError(null);
        }, 500);
    };

    const handleCloseDialog = () => {
        AnalyticsTracker.trackEvent(CATEGORY, "hande close dialog", "", 0);
        closeDialog();
    };

    return (
        <Dialog open={open} TransitionComponent={Transition} onClose={handleCloseDialog}>
            <DialogContent style={{backgroundColor: "#eb4727"}}>
                <div className="orange-bg">
                    <div className="register-container">
                        {!registered ?
                            <div>
                                <div className="forza register-title">
                                    {"Have Questions?"}
                                </div>
                                <div className="register-message pt-sans">
                                    {"Leave your Phone # or Email Address and we'll get in contact with you."}
                                </div>
                                <div className="register-email">
                                    <TextField
                                        label="Name"
                                        placeholder="John Smith"
                                        fullWidth={true}
                                        value={name}
                                        onChange={onNameChange}
                                        inputProps={{style: {fontFamily: "PT Sans"}}}
                                        onKeyDown={onEmailKeyDown}
                                        autoFocus />
                                </div>
                                <div className="register-email">
                                    <TextField
                                        label="Phone Number"
                                        placeholder="555-555-5555"
                                        fullWidth={true}
                                        value={phoneNumber}
                                        onChange={onPhoneChange}
                                        inputProps={{style: {fontFamily: "PT Sans"}}}
                                        onKeyDown={onEmailKeyDown}
                                        error={Boolean(phoneError)}
                                        helperText={phoneError ? phoneError : ""}
                                        type="tel"/>
                                </div>
                                <div className="register-email">
                                    <TextField
                                        label="Email Address"
                                        placeholder="john.smith@gmail.com"
                                        fullWidth={true}
                                        value={email}
                                        onChange={onEmailChange}
                                        inputProps={{style: {fontFamily: "PT Sans"}}}
                                        onKeyDown={onEmailKeyDown}
                                        error={Boolean(emailError)}
                                        helperText={emailError ? emailError : ""}
                                        type="email" />
                                </div>
                                <div className="register-email">
                                    <TextField
                                        label="Message (Optional)"
                                        placeholder="(Optional)"
                                        fullWidth={true}
                                        value={message}
                                        onChange={onMessageChange}
                                        inputProps={{style: {fontFamily: "PT Sans"}}}
                                        onKeyDown={onEmailKeyDown} />
                                </div>
                                <div className="register-actions flex">
                                    <div className="register-action-cancel" onClick={onCancelClick}>Cancel</div>
                                    <div className="ll-button ll-button-dark register-action-ok" onClick={onRegisterClick}>
                                        Submit
                                    </div>
                                </div>
                            </div>
                            :
                            <div>
                                <div className="forza register-title">
                                    {"Thank You!"}
                                </div>
                                <div className="register-message pt-sans">
                                    {"You have successfully registered " + email}
                                </div>
                                <div className="register-actions flex">
                                    <div className="ll-button ll-button-dark register-action-ok" onClick={onDoneClick}>
                                        Done
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    )
};

export default RegisterDialog;
