import React from 'react';

import '../../css/Common.css';
import '../../css/ProductWeTested.css';

const ProductWeTested = () => {
    return (
        <div className="product-we-tested-container flex">
            <div className="product-we-tested-content outter-padding">
                <h2 className="product-we-tested-headline">
                    We tested them so <span className="orange">you could count on them.</span>
                </h2>
                <div className="pt-sans">
                    Rigorous testing allows us to transform a firefighter&#39;s innovation into a product you can depend on.
                </div>
            </div>
        </div>
    );
};

export default ProductWeTested;
