import React from 'react';

import '../../css/Common.css';
import '../../css/ProductBuiltFromBest.css';

const ProductBuiltFromBest = () => {
    return (
        <div className="product-built-from-best-container flex">
            <div className="product-built-from-best-info outter-padding">
                <h2 className="product-built-from-best-info-headline orange">
                    Built from the best, for the best.
                </h2>
                <ul className="pt-sans">
                    <li>Array of strobing green LEDs positioned for visibility</li>
                    <li>High-intensity white LED angled downward to illuminate obstacles</li>
                    <li>Die cast aluminum alloy </li>
                    <li>Internal gyroscope detects ladder angle to turn lights on/off automatically</li>
                    <li>9v battery powers lights at full capacity during 12-hour shift</li>
                    <li>Accessible test button integrates with daily check protocols</li>
                    <li>Designed for easy battery replacement</li>
                </ul>
            </div>
        </div>
    );
};

export default ProductBuiltFromBest;
