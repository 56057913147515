import React from 'react';

import logo from '../../img/logo-symbol-dark.svg';

import '../../css/Common.css';
import '../../css/ProductOrderBumper.css';

const ProductOrderBumper = ({ onOrderClick }) => {
    return (
        <div className="product-order-bumper flex center">
            <div className="flex center">
                <div>
                    <img src={logo} className="product-order-bumper-logo" alt="LadderLight logo | Ladder Light" />
                </div>
                <h2 className="product-order-bumper-content">
                    Replace your <span className="white">end caps</span>
                </h2>
                <div className="ll-button ll-button-dark" onClick={onOrderClick}>
                    order now
                </div>
            </div>
        </div>
    );
};

export default ProductOrderBumper;
