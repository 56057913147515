import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';

import '../../css/Common.css';
import '../../css/ProductVideo.css';

const titles = [
    "Identify ladder location quickly.",
    "Illuminates a safe path to depature.",
    "Integrates with existing ladders.",
    "Turns on automatically",
];

const descriptions = [
    "An array of strobing green beacon lights increase visibility in smoke, darkness, and flames.",
    "A high-intensity white LED adds visibility to the departure area so you can step safely.",
    "End caps fit seamlessly and install in minutes without disruption to ladder functionality. No additional parts or risky protrusions.",
    "No extra buttons or steps－lights activate during ladder operations and turn off to preserve battery life during storage and transport."
];

const ProductVideo = () => {
    const [videoSection, setVideoSection] = useState(0);

    const onTimeUpdate = (event) => {
        let duration = event.target.duration;
        let currentTime = event.target.currentTime;
        let sectionDuration = duration / titles.length;

        let currentSection = getCurrentVideoSection(videoSection, currentTime, sectionDuration);

        if (currentSection !== videoSection) {
            setVideoSection(currentSection);
        }
    };

    const getCurrentVideoSection = (section, currentTime, sectionDuration) => {
        if (section === titles.length) {
            return 0;
        } else if (currentTime < ((section + 1) * sectionDuration) -1) {
            return section;
        }
        return getCurrentVideoSection(section + 1, currentTime, sectionDuration);
    };

    return (
        <div className="product-video-container outter-padding">
            <div className="flex center">
                <div className="product-video-headline forza orange">
                    <h2>The smartest way to add functionality to your ladder</h2>
                </div>
            </div>
            <Grid container spacing={24}>
                <Grid item xs={12} sm={6} className="flex center product-video-placeholder">
                    <video loop muted autoPlay playsInline
                           className="product-video-img"
                           src="websiteLoop.mp4"
                           onTimeUpdate={onTimeUpdate} />
                </Grid>
                <Grid item xs={12} sm={6} className="flex center">
                    <div className="product-video-features">
                        {titles.map((title, index) => {
                            return (
                                <div
                                    key={"product-video-feature-" + index}
                                    className={"white pt-sans product-video-feature" + (videoSection === index ? " product-video-feature-selected" : "")}>
                                    <div className="product-video-feature-title">
                                        {title}
                                    </div>
                                    <div className="product-video-feature-description white">
                                        {descriptions[index]}
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </Grid>
            </Grid>
        </div>
    )
};

export default ProductVideo;
