import React from 'react';
import Grid from '@material-ui/core/Grid';

import AnalyticsTracker from './AnalyticsTracker';

import location from '../../img/address_icon@2x.png';
import phone from '../../img/phone_icon@2x.png';
import instagram from '../../img/instagram.svg';
import facebook from '../../img/facebook.svg';
import twitter from '../../img/twitter.svg';

import '../../css/Contact.css';
import '../../css/Common.css';

const CATEGORY = "contact";

const Contact = ({ page, onMessageClick }) => {

    const onContactClick = () => {
        AnalyticsTracker.trackEvent(CATEGORY, "send message click", page, 0);
        onMessageClick();
    };

    const onFacebookClick = () => {
        AnalyticsTracker.trackEvent(CATEGORY, "facebook click", "", 0);
        goToPage('https://www.facebook.com/SmarterEndCaps/');
    };

    const onTwitterClick = () => {
        AnalyticsTracker.trackEvent(CATEGORY, "twitter click", "", 0);
        goToPage('https://twitter.com/ladderlight');
    };

    const onInstagramClick = () => {
        AnalyticsTracker.trackEvent(CATEGORY, "instagram click", "", 0);
        goToPage('https://www.instagram.com/ladderlight/');
    };

    const goToPage = (url) => {
        if (window.innerWidth > 800) {
            window.open(url);
        } else {
            document.location = url;
        }
    };

    return (
        <div id="home-contact" className="home-contact-wrapper">
            <div className="outter-padding">
                <h2 className="home-contact-headline">
                    Get in touch with us
                </h2>
                <div className="home-contact-button-wrapper">
                    <div className="ll-button ll-button-dark" onClick={onContactClick}>
                        Send us a message
                    </div>
                </div>
            </div>
            <div className="home-contact-options pt-sans outter-padding">
                <div className="home-contact-options-wrapper">
                    <Grid container>
                        <Grid item xs={6}>
                            <a target="_blank" rel="noopener noreferrer" href="https://maps.google.com/?q=1706%20Hueco%20Mountain%20Trail%20Round%20Rock%20TX%2078664" className="home-contact-option-link">
                                <div className="home-contact-option">
                                    <div className="home-contact-option-icon">
                                        <img src={location} alt="location icon | Ladder Light" className="home-contact-option-icon-img" />
                                    </div>
                                    <div>
                                        <div>1706 Hueco Mountain Trail</div>
                                        <div>Round Rock, TX 78664</div>
                                    </div>
                                </div>
                            </a>
                        </Grid>
                        <Grid item xs={6}>
                            <a href="tel:1-512-567-4491" className="home-contact-option-link">
                                <div className="home-contact-option">
                                    <div className="home-contact-option-icon">
                                        <img src={phone} alt="phone icon | Ladder Light" className="home-contact-option-icon-img" />
                                    </div>
                                    <div>
                                        512.567.4491
                                    </div>
                                </div>
                            </a>
                        </Grid>
                    </Grid>
                </div>
            </div>
            <div className="outter-padding">
                <img src={facebook} className="home-contact-social-icon" alt="Ladder Light Facebook | Ladder Light" onClick={onFacebookClick}/>
                <img src={twitter} className="home-contact-social-icon" alt="Ladder Light Twitter | Ladder Light" onClick={onTwitterClick} />
                <img src={instagram} className="home-contact-social-icon" alt="Ladder Light Instagram | Ladder Light" onClick={onInstagramClick} />
            </div>
        </div>
    );
}

export default Contact;
